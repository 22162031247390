<template>
  <footer class="footer">
    <div class="footer__container container">
      <h2 class="footer__title">Vi står bak {{ item.name }}-prosjektet</h2>
      <div class="footer__content">
        <div class="footer__company-description" v-html="company.description"></div>
        <div v-if="$path('logo', company)" class="footer__company-logos">
          <template v-for="(logo, i) in company.logo">
            <img :key="i + 'img'" :src="logo.url" :alt="logo.url" />
          </template>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <div class="footer__links">
          <img class="footer__links-logo" :src="logo" :alt="item.name" />
          <a href="/api/legal/privacy/tenant" target="_blank"
            >{{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}</a
          >
          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        </div>

        <div class="footer__copyright">
          <div>
            &copy; {{ year }} - {{ $t('pageMadeBy') }}
            <a href="https://www.kvass.no" target="_blank">Kvass</a> -
            <a href="https://riktigspor.no/" target="_blank">design: Riktig Spor</a>
          </div>
        </div>
        <SoMeFooter
          v-if="someCustomField.length !== 0"
          :value="someCustomField"
          :icons="someIcons"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'
import CM from '@/gdpr'

export default {
  props: {
    navigation: Array,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someIcons() {
      return GetCustomField('custom-icons', this.item.customFields) || {}
    },
    company() {
      return GetCustomField('company', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    logo() {
      //return logo based on primary-color lightness
      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted
      return API.logo
    },
  },
  methods: {
    editConsents() {
      CM.show()
    },
  },
  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>
<style lang="scss">
.footer {
  background-color: var(--background-secondary);
  padding-top: 3rem;

  &__content {
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    background-color: white;

    @include respond-below('tablet') {
      padding: 2rem 1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
    }
  }
  &__company {
    &-description {
      line-height: 1.5;
      max-width: 600px;
    }
    &-logos {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      img {
        width: 250px;
      }
      @include respond-below('phone') {
        gap: 1rem;
        img {
          width: 200px;
        }
      }
    }
  }

  &__container {
    grid-gap: 2rem;
    background-color: var(--background-secondary);
    color: black;
  }
  &__bottom {
    padding: 3rem 0;
    background-color: var(--primary);
    font-size: 0.8rem;

    @include respond-below('tablet') {
      padding-top: 1rem;
    }
    .container {
      color: white;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include respond-below('tablet') {
        gap: 2rem;
      }
    }
  }

  &__copyright {
    align-self: center;
    @include respond-below('phone') {
      max-width: 230px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      & + a {
        margin-top: 0.5rem;
      }
    }
  }

  &__menu {
    &-action {
      text-transform: uppercase;
      font-weight: normal;
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--secondary);
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }
      &:hover {
        background-color: transparent;
        &::before {
          transform: scaleX(1);
        }
      }

      &--indent {
        margin-left: 0.5rem;
      }

      & + & {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 0;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    &-logo {
      width: 200px;
      @include respond-below('phone') {
        width: 150px;
      }
    }
  }

  &__social-icons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    a {
      margin-right: 0.8rem;
    }
  }

  .user-profile {
    display: none;
  }
}
</style>
