<template>
  <Lead class="project-lead" :project="item.id" :reference="{ onModel: 'Project', ref: item.id }" />
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Root', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.project-lead {
  max-width: 800px;
  margin: 0 auto;
  color: var(--primary-contrast);
  .elder-button--primary {
    background-color: var(--secondary);
  }
  .elder-input__label {
    color: var(--primary-contrast);
  }
  .lead__form {
    background-color: transparent;
  }
  .elder-checkbox__label,
  a {
    font-size: 0.8rem;
  }
}
</style>
