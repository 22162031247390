<template>
  <div class="footer__social">
    <strong v-if="value.title" class="footer__title">
      <span v-if="value.title" v-html="value.title"></span>
    </strong>
    <span v-if="value.description" v-html="value.description"></span>
    <div class="footer__social-icons">
      <template v-for="entry in soMeEntries">
        <a v-if="entry.link" :key="entry.type" :href="entry.link" target="_blank">
          <img
            class="footer__social-custom-icon "
            v-if="icons[entry.type + '-icon']"
            :src="icons[entry.type + '-icon'].url"
            :alt="icons[entry.type + '-icon'].name"
          />
          <FontAwesomeIcon v-else :icon="entry.icon" />

          <span>
            {{ entry.title }}
          </span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object],
    },
    icons: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    soMeEntries() {
      return [
        {
          type: 'facebook',
          icon: ['fab', 'facebook'],
          link: this.value.facebook_url,
          title: 'Følg oss på facebook',
        },
        {
          type: 'intagram',
          icon: ['fab', 'instagram'],
          link: this.value.instagram_url,
          title: 'Følg oss på instagram',
        },
        {
          type: 'linkedin',
          icon: ['fab', 'linkedin'],
          link: this.value.linkedin_url,
          title: 'Følg oss på linkedin',
        },
        {
          type: 'twitter',
          icon: ['fab', 'twitter'],
          link: this.value.twitter_url,
          title: 'Følg oss på twitter',
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.footer {
  &__social-icons {
    a {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      span {
        color: var(--background-secondary);
      }
      svg {
        font-size: 2.5em;
      }
    }
  }

  .footer__social-custom-icon {
    width: 65px;
  }
}
</style>
