<template>
  <div class="project-interior">
    <template v-for="(item, index) in value">
      <div class="project-interior__item" :key="index">
        <Media v-if="item.image" class="project-interior__image" :value="item.image" />

        <div class="project-interior__description">
          <img
            v-if="item.logo"
            class="project-interior__logo"
            :src="item.logo.url"
            :alt="item.name"
          />

          <div v-if="item.description" v-html="item.description"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'
import Media from '@kvass/media-render/Types/Image'

export default {
  computed: {
    ...mapState('Project', ['item']),

    value() {
      return GetCustomField('interior', this.item.customFields) || []
    },
  },
  components: {
    Media,
  },
}
</script>

<style lang="scss">
.project-interior {
  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include respond-below('tablet') {
      grid-template-columns: 1fr;
    }
  }

  &__description {
    line-height: 1.6;
    padding: 2rem 3rem;
    background-color: var(--background-secondary);
    @include respond-below('phone') {
      padding: 0rem;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
  }

  &__logo {
    width: 180px;
    @include respond-below('tablet') {
      margin-top: 1rem;
      width: 160px;
    }
  }
}
</style>
