<template>
  <div class="project-gallery">
    <h2>{{ heading ? heading : 'Nytt, lekkert og enkelt!' }}</h2>
    <Carousel
      :style="style"
      class="project-gallery__wrapper"
      :per-page="1"
      autoplay
      :navigation-enabled="true"
      :pagination-enabled="false"
      navigation-prev-label=""
      navigation-next-label=""
      :autoplay-timeout="4000"
      loop
      :navigation-click-target-size="1"
      :key="items.map(i => i.image.url).join('-')"
    >
      <Slide v-for="(item, index) in items" :key="index">
        <div class="project-gallery__image">
          <img class="content" :src="item.image.url" ref="items" />
        </div>
        <div class="project-gallery__description" v-html="item.description"></div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'
import { Carousel, Slide } from 'vue-carousel'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return (GetCustomField('gallery', this.item.customFields) || []).filter(i =>
        this.$path('image.url', i),
      )
    },
    heading() {
      return GetCustomField('gallery-heading', this.item.customFields) || ''
    },
    style() {
      let customIcons = GetCustomField('custom-icons', this.item.customFields) || {}

      return {
        '--arrow-left': customIcons['arrow-left'] ? `url(${customIcons['arrow-left'].url})` : '',
        '--arrow-right': customIcons['arrow-right'] ? `url(${customIcons['arrow-right'].url})` : '',
      }
    },
  },
  components: {
    Carousel,
    Slide,
  },
}
</script>

<style lang="scss">
.project-gallery {
  color: white;
  text-align: center;
  &__image {
    @include aspect-ratio(16, 9);
    margin: 0 auto;
    .content {
      max-width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 1rem;
    }
  }
  &__description {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 1.5rem;
    @include respond-below('phone') {
      margin-top: 0.5rem;
    }
  }

  .VueCarousel-navigation-prev {
    transform: translateY(-100%) translateX(-100%);
    left: 2rem;
    @include respond-below('phone') {
      left: 1rem;
      transform: translateY(-250%) translateX(-100%);
    }
  }
  .VueCarousel-navigation-next {
    transform: translateY(-100%) translateX(100%);
    right: 2rem;
    @include respond-below('phone') {
      right: 1rem;
      transform: translateY(-250%) translateX(100%);
    }
  }
  .VueCarousel-navigation-button {
    color: white;
    outline: none !important;
    width: 90px;
    @include respond-below('phone') {
      width: 40px;
    }
  }
  .VueCarousel-navigation-next::after {
    content: var(--arrow-right);
  }
  .VueCarousel-navigation-prev::after {
    content: var(--arrow-left);
  }
}
</style>
