<template>
  <div class="project-process">
    <h2>Hvor i prosessen er vi?</h2>
    <div v-if="items && items.length" class="project-process__content">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="project-process__item"
        :class="{
          'project-process__item--active': checkActive(index),
          'project-process__item--check': item['check-progress'],
        }"
      >
        <img
          v-if="item.icon"
          class="project-process__item-icon"
          :src="item.icon.url"
          :alt="item.title"
        />

        <div class="project-process__item-title">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return GetCustomField('project-progress', this.item.customFields) || []
    },
  },
  methods: {
    checkActive(index) {
      if (this.items[index]['check-progress']) return false
      //index = 0 is always active
      if (index === 0) return true
      if (this.items[index]['check-progress']) return true
      return false
    },
  },
}
</script>

<style lang="scss">
.project-process {
  margin: 0 auto;
  color: white;

  &__content {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: baseline;

    @include respond-below('phone') {
      gap: 0.5rem;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    @include respond-below('phone') {
      gap: 0.5rem;
    }

    &-icon {
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-origin: content-box;
    }

    &-title {
      display: none;
      text-align: center;

      @include respond-below('phone') {
        font-size: 0.4em;
      }
    }
    &--check,
    &--active {
      .project-process__item-title,
      .project-process__item-icon {
        display: initial;
      }
    }
  }
}
</style>
