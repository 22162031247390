<template>
  <div class="project-hero">
    <div class="project-hero__slide">
      <Slider v-if="items.length" :value="items" :display-thumbnail="true" />
    </div>

    <div class="project-hero__content">
      <div class="project-hero__content-wrapper">
        <div class="project-hero__content-header-text" v-html="headerText"></div>
        <img class="project-hero__content-logo" :src="logo" :alt="item.name" />
        <div v-if="item.id" class="project-hero__content-status">
          <template v-if="!stats.total">
            {{ $t('residentialStatus.upcoming') + '!' }}
          </template>
          <template v-else>
            {{ $t('residentialStatus.sale') + '!' }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView } from '@/utils'
import Slider from '@kvass/media-render/Slider'
import { GetCustomField } from '@/utils'
import API from '@/api'

export default {
  data() {
    return {
      current: null,
      looper: null,
    }
  },
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
    headerText() {
      let text = GetCustomField('header-text', this.item.customFields) || ''
      if (!text) return
      let first = text.split(' ')[0]
      return `<h1>${[first, '<br>', text.replace(first, '')].join('')}</h1>`
    },
    logo() {
      return API.logo
    },
    enabledStatus() {
      return [
        { key: 'sale' },
        { key: 'sold' },
        { key: 'reserved' },
        { key: 'upcoming' },
      ].filter(e => this.getStat(e.key))
    },
    stats() {
      return this.item.stats || {}
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
    getStat(key) {
      let keys = key instanceof Array ? key : [key]

      return keys.reduce((r, c) => r + this.stats[c], 0)
    },
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.project-hero {
  $height: 100vh;

  position: relative;
  height: $height;
  width: 100%;
  background-color: css-alpha('primary', 0.1);

  display: flex;
  flex-direction: column;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  &__content {
    $size: 500px;
    width: $size;
    height: $size;
    background-color: var(--background-primary);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 30%);
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include respond-below('phone') {
      transform: translate(-50%, 10%);
    }

    @include respond-below('tablet') {
      width: $size / 1.2;
      height: $size/ 1.2;
    }

    @include respond-below('phone') {
      width: $size / 1.6;
      height: $size/ 1.6;
    }
    &-wrapper {
      padding: 1rem 0;
      border-radius: 50%;
      margin: 0 auto;
      max-width: 400px;
      @include respond-below('phone') {
        width: 200px;
      }
      @include respond-below('tablet') {
        width: 300px;
      }
    }

    &-logo {
      width: 350px;
      @include respond-below('tablet') {
        width: 200px;
      }
    }

    &-header-text {
      color: var(--primary) !important;
      h1 {
        margin: 0;
      }
    }
    &-status {
      text-transform: uppercase;
      color: var(--secondary);
      font-size: 1.4em;
      max-width: 150px;
      margin: 0 auto;
      @include respond-below('tablet') {
        font-size: 1.2em;
      }
    }
  }

  &__slide {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    iframe {
      min-height: $height;
    }
  }
}
</style>
