<template>
  <Lead
    class="residential-lead"
    :project="$path('project.id', item)"
    :reference="{ onModel: 'Residential', ref: item.id }"
  />
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Residential', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.residential-lead {
  max-width: 800px;
  margin: 0 auto;
  color: var(--primary-contrast);
  .elder-button--primary {
    background-color: var(--secondary);
  }
  .elder-input__label {
    color: var(--primary-contrast);
  }
  .lead__form {
    background-color: transparent;
  }
}
</style>
