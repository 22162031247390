<template>
  <div class="summary">
    <div class="summary__price-wrapper">
      <div class="summary__price">
        <div class="summary__price-value">{{ price }}</div>
        <div v-if="hasPrice && item.status === 'sale'" class="summary__price-label">
          <template v-if="$path('sale.fee.included', item)">{{
            $t('residentialSaleFeeIncluded', { value: null })
          }}</template>
          <template v-else> + {{ $t('residentialSaleFee').toLowerCase() }} </template>
          <template v-if="fee">({{ fee | Currency }})</template>
        </div>
      </div>
    </div>
    <div
      class="summary__property"
      v-for="(property, index) in properties"
      :key="property.key + index"
      :data-key="property.key"
    >
      <div>
        <div class="summary__property-label">{{ property.label || $tc(property.key) }}</div>
        <div class="summary__property-value">{{ property.formatted }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Currency } from '@/filters'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    hasPrice() {
      return Boolean(this.$path('item.sale.value'))
    },
    price() {
      if (!this.item.status) return
      let price = Currency(this.$path('item.sale.value'))
      switch (this.item.status) {
        case 'sale':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return price
        case 'reserved':
          return `${this.$t('residentialStatus.' + this.item.status)}${
            price && !KvassConfig.get('siteSettings.hideReservedPrice') ? ` (${price})` : ''
          }`
        default:
          return this.$t('residentialStatus.' + this.item.status)
      }
    },
    fee() {
      return this.$path('item.sale.fee.value')
    },
    properties() {
      if (!this.item.properties) return []
      return this.item.properties
        .filter(
          p =>
            (KvassConfig.get('siteSettings.featuredResidentialProperties') || []).includes(p.key) &&
            p.formatted,
        )
        .slice(0, 3)
    },
  },
  filters: {
    Currency,
  },
}
</script>

<style lang="scss">
.summary {
  color: $dark;
  display: flex;
  flex-direction: column;

  @include respond-below('tablet') {
    font-size: 0.8em;
  }

  & > div {
    border-bottom: 1px solid $border-color;
    padding-left: 2em;
    display: flex;
    flex-grow: 1;

    @include respond-below('tablet') {
      padding: 2em;
    }
  }

  & > div > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__address {
    font-size: 1.2em;
    display: flex;
    flex-direction: row !important;

    &-dash {
      opacity: 0.3;
      margin: 0 0.5em;
    }
  }

  &__title {
    font-size: 1.5em;
    opacity: 0.8;
  }

  &__price {
    &-value {
      font-size: 2.3em;
      font-weight: bold;
    }

    &-label {
      opacity: 0.8;
    }
  }

  &__property {
    &-label {
      opacity: 0.75;
    }

    &-value {
      font-weight: bold;
      font-size: 1.2em;
      margin-top: 0.25em;
    }
  }
}
</style>
