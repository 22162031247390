<template>
  <div class="additional-info">
    <div
      v-for="(item, index) in value"
      :key="index"
      class="kvass-image-text__item"
      :class="{ 'kvass-image-text__item--reverse-order': item.revereseOrder }"
    >
      <ImageComponent
        class="kvass-image-text__image"
        :value="{
          url: $path('image.url', item) || item.image,
          name: item.title,
          description: $path('image.description', item),
        }"
        v-bind="$attrs"
      />

      <div class="kvass-image-text__content">
        <slot name="content" v-bind="item">
          <h2 class="kvass-image-text__title">{{ item.title }}</h2>
          <div class="kvass-image-text__description" v-html="item.description"></div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'
import ImageComponent from '@kvass/media-render/Types/Image'

export default {
  computed: {
    ...mapState('Project', ['item']),

    value() {
      let data = GetCustomField('additional-info', this.item.customFields) || []
      return data.map((item, index) => {
        let titleRegexp = /<h2>(.+)<\/h2>/
        let titleMatch = titleRegexp.exec(item.content)

        return {
          title: titleMatch && titleMatch[1],
          description: item.content ? item.content.replace(titleRegexp, '') : null,
          image: item.image ? item.image.url : null,
          revereseOrder: index < data.length / 2,
        }
      })
    },
  },
  components: {
    ImageComponent,
  },
}
</script>

<style lang="scss">
.additional-info {
  $breakAt: 850px;

  column-gap: 2.5rem !important;
  column-count: 2;
  @media (max-width: 900px) {
    column-gap: 4rem;
  }
  @media (max-width: 600px) {
    column-count: 1;
  }

  .kvass-image-text {
    &__content {
      margin: 2rem 0;
    }

    &__item {
      padding-top: 0rem !important;
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      align-items: center;
      color: white;

      break-inside: avoid;

      @media (max-width: 900px) {
        padding-top: 6rem;
      }
      @media (max-width: 600px) {
        padding-top: 3rem;
      }

      &:nth-child(3) {
        color: var(--primary);
      }
      &:nth-child(2) {
        color: black;
      }

      &:nth-child(3) {
        .kvass-image-text__content {
          margin-left: 3rem;
        }
      }
      &:nth-child(4) {
        .kvass-image-text__content {
          margin-left: 3rem;
        }
      }
      &:nth-child(5) {
        .kvass-image-text__content {
          margin-left: 3rem;
        }
      }

      @media (max-width: $breakAt) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
      }

      &--reverse-order {
        @media (min-width: $breakAt + 1) {
          flex-direction: column-reverse;
          justify-content: flex-end;

          .kvass-image-text__content {
            text-align: left;
            margin-right: auto;
            margin-left: 0;
          }
        }
      }

      * {
        direction: ltr;
        text-align: left;
      }

      img {
        height: auto;
        width: 100%;
      }

      & + .kvass-image-text__item {
        @media (max-width: $breakAt) {
          margin-top: 2rem;
        }
      }
    }

    &__content {
      @media (max-width: $breakAt) {
        max-width: none;
      }

      @media (max-width: 1200px) {
        margin: 0 !important;
      }
    }

    .kvass-image-text__image {
      max-width: 100%;
    }
    .kvass-image-text__content {
      margin-top: 1rem;
    }
    .kvass-image-text__title {
      margin: 0;
    }
  }
}
</style>
