<template>
  <div v-if="value.length" class="roles">
    <h2 class="roles__title">Kontakt våre meglere</h2>

    <div class="roles__list">
      <div
        class="roles__item"
        v-for="(item, index) in value"
        :key="index"
        :data-id="item.id"
        :data-email="item.email"
      >
        <div class="roles__avatar" :style="{ backgroundImage: `url(${item.avatar})` }"></div>
        <div class="roles__content">
          <h2 class="roles__name">{{ item.name }}</h2>
          <div v-if="item.title" class="roles__user-title">{{ item.title }}</div>
          <a v-if="item.email" class="roles__email" :href="`mailto:${item.email}`">{{
            `${$t('email')}: ${item.email}`
          }}</a>
          <a v-if="item.phone" class="roles__phone" :href="`tel:${item.phone}`">{{
            `${$t('phone')}: ${item.phone}`
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Root', ['item']),
    value() {
      return this.$path('item.roles.salesman') || []
    },
  },
}
</script>

<style lang="scss">
.roles {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    @include respond-below('tablet') {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__name {
    margin: 0;
  }
  &__title {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &__avatar {
    $size: 500px;

    height: $size;
    width: $size;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__user-title {
    margin: 1rem 0;
    font-style: italic;
    @include respond-below('tablet') {
      margin: 0.5rem 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: $border-radius;

    @include respond-below('phone') {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1rem;

      .roles {
        &__name {
          margin: 0;
        }

        &__avatar {
          $size: 150px;
          width: $size;
          height: $size;
        }

        &__email,
        &__phone {
          display: block;
          margin-top: 3px;
        }
      }
    }
  }

  &__name {
  }

  &__email,
  &__phone {
    text-decoration: none;
    white-space: pre-wrap;
    color: black;
  }
}
</style>
