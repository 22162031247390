<template>
  <Residentials :filter="filter" :project="project" :properties="properties" />
</template>

<script>
import API from '@/api'
import Bus from '@/bus'
import { mapState } from 'vuex'

import Residentials from '@kvass/residentials/src/Component'

export default {
  data() {
    return {
      filter: {},
      project: API.project,
    }
  },
  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>

<style lang="scss">
.residentials {
  &__title {
    color: var(--primary-contrast);
  }
  &__scroller th {
    background-color: var(--primary);
    color: white;
  }
  .table--default tbody tr:nth-child(odd) {
    background-color: var(--background-primary);
  }
  .table--default tbody tr:nth-child(even) {
    background-color: css-lighten('background-primary', 3%);
  }
  .residentials__sort-trigger .elder-button__label {
    color: white;
    background-color: var(--primary) !important;
  }
}
</style>
