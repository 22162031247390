<template>
  <div class="construction">
    <div class="construction__description" v-html="data.description" />

    <div class="construction__images">
      <template v-for="(image, index) in data.images">
        <img :key="index" class="construction__image" :src="image.url" :name="image.name" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { RandomBetween } from '@/utils'

import { GetCustomField } from '@/utils'

export default {
  computed: {
    ...mapState('Project', ['item']),

    data() {
      return GetCustomField('construction', this.item.customFields) || []
    },
  },
}
</script>

<style lang="scss">
.construction {
  display: grid;
  grid-gap: 8rem;
  grid-template-columns: repeat(2, 1fr);

  @include respond-below('tablet') {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  h2:first-child {
    color: var(--primary);
  }
  &__title {
    margin-bottom: 1.5rem;
  }

  &__description {
    line-height: 1.6;

    &-sample {
      display: inline-block;
      height: 5px;
      background-color: $border-color;
      margin-right: 0.5em;
    }
  }

  &__brochure {
    margin-top: 1.5rem;
    display: inline-block;
  }

  &__images {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include respond-below('tablet') {
      padding-top: 0rem;
    }
  }

  &__image {
    max-height: 280px;
    object-position: center;
    object-fit: cover;
    width: 100%;
  }
}
</style>
