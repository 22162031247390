<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Hero class="section__hero" />

      <section class="section section__info ">
        <div class="container">
          <ScrollAnchor value="info" offset="-8rem" :delay="750" />
          <Info />
        </div>
      </section>

      <section class="section section__additional-info">
        <div class="container">
          <ScrollAnchor value="additional-info" offset="-8rem" :delay="750" />
          <AdditionalInfo />
        </div>
      </section>

      <section class="section section__project-properties ">
        <div class="container">
          <ProjectProperties />
        </div>
      </section>

      <section class="section section__construction">
        <div class="container">
          <Construction />
          <ResidentialTemplates />
        </div>
      </section>

      <section class="section section__images">
        <div class="container">
          <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
          <Gallery />
        </div>
      </section>

      <section class="section section__interior">
        <div class="container">
          <Interior />
        </div>
      </section>

      <section class="section section__map section--no-p">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <Map />
      </section>

      <section class="section section__progress-bar">
        <div class="container">
          <ProgressBar />
        </div>
      </section>

      <section v-if="$path('flatfinders.length', item)" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" :delay="750" />
        <div class="container">
          <Flatfinder />
        </div>
      </section>
      <section
        v-if="$path('stats.total', item)"
        class="section section__residentials section--no-pt"
      >
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials v-if="$path('stats.total', item)" />
        </div>
      </section>
      <section class="section section__attachments" v-if="$path('media.attachments.length', item)">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>

      <section class="section section__roles">
        <div class="container">
          <ScrollAnchor value="roles" offset="-8rem" :delay="750" />
          <Openhouse v-if="showOpenhouse" class="mb-6" />
          <Roles />
        </div>
      </section>

      <section class="section section__lead">
        <div class="container">
          <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
          <Lead />
        </div>
      </section>

      <Cta />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import Roles from '@/components/Roles'

import ScrollAnchor from '@kvass/scroll-anchor'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
    // showResidentialTemplates() {
    //   if (!this.item) return false
    //   return GetCustomField('residential-templates', this.item.customFields)
    // },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
    Roles,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }
    &--no-pt {
      padding-top: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__additional-info {
      background-color: var(--background-secondary);
    }
    &__info {
      background-color: var(--primary);
      color: white;
      padding-top: 10rem;
      @include respond-below('phone') {
        padding-top: 2.5rem;
      }
    }
    &__shop {
      min-height: 100vh;
    }
    &__map {
      min-height: 600px;
    }

    &__project-properties {
      padding: 2rem 0;
      .container {
        @include respond-below('phone') {
          padding: 0 0.5rem;
        }
      }
    }
    &__project-properties,
    &__images,
    &__lead {
      background-color: var(--primary);
    }

    &__interior {
      @include respond-below('phone') {
        background-color: var(--background-secondary);
      }
    }
    &__progress-bar {
      background-color: var(--primary);
    }
    &__residentials,
    .leaflet-container,
    &__flatfinder {
      background-color: var(--background-secondary);
    }
  }
}
</style>
