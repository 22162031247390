<template>
  <LoaderComponent :value="promise" class="template-residential-templates">
    <div
      v-if="value.description"
      class="template-residential-templates__description"
      v-html="value.description"
    ></div>

    <div class="template-residential-templates__items">
      <router-link
        :to="{ name: 'ResidentialTemplate', params: { id: template.id } }"
        class="template-residential-templates__item-card"
        v-for="(template, index) in data"
        :key="index"
      >
        <div class="template-residential-templates__item-card-header">
          <div class="template-residential-templates__item-card-title">
            {{ template.name }}
          </div>
          <div
            class="template-residential-templates__item-card-image"
            :style="{
              backgroundImage: `url(${getThumbnail(template)})`,
            }"
          ></div>
        </div>
        <div class="template-residential-templates__item-card-content">
          <template v-for="(property, i) in getProperties(template.properties)">
            <div
              class="template-residential-templates__item-card-property"
              :key="index + i + 'property'"
            >
              <div class="template-residential-templates__item-card-property-label">
                {{ property.label || $tc(property.key) }}
              </div>
              <div class="template-residential-templates__item-card-property-value">
                {{ property.formatted }}
              </div>
            </div>
          </template>
        </div>
      </router-link>
    </div>
  </LoaderComponent>
</template>

<script>
import { GetCustomField } from '@/utils'
import { mapState } from 'vuex'
import API from '@/api'
import { path } from 'ramda'

import { ButtonComponent } from 'vue-elder-button'
import { LoaderComponent } from 'vue-elder-loader'

export default {
  watch: {
    value: {
      handler: 'fetch',
      immediate: true,
    },
  },
  data() {
    return {
      data: null,
      promise: null,
    }
  },
  computed: {
    ...mapState('Project', ['item']),
    value() {
      return GetCustomField('residential-templates', this.item.customFields) || []
    },
  },

  methods: {
    getThumbnail(item) {
      let match = (path(['media', 'cover'], item) || []).find(i => i.type.startsWith('image'))
      if (!match) return

      return match.url
    },
    getProperties(value) {
      return (value || [])
        .filter(p => p.formatted)
        .map(i => {
          return {
            ...i,
            formatted: i.formatted.replace('m²', 'KVM'),
          }
        })
    },

    fetch() {
      return (this.promise = API.getResidentialTemplates({
        ids: this.value.references,
      }).then(data => {
        this.data = data.ResidentialTemplates
        return data
      }))
    },
  },

  components: {
    ButtonComponent,
    LoaderComponent,
  },
}
</script>

<style lang="scss">
.template-residential-templates {
  a {
    text-decoration: none;
    color: black;
  }
  &__description {
    line-height: 1.5;
    max-width: 800px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 2rem;
    margin-top: 2rem;
  }

  &__item-card {
    background-color: var(--primary);
    transition: transform 0.62s cubic-bezier(0.05, 0.2, 0.1, 1);

    &:hover {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
    }
    &-header {
      display: flex;
      max-width: 400px;
      margin: 0 auto;

      padding: 2rem;
      gap: 1rem;
    }

    &-image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
    }

    &-title {
      font-size: 4.5rem;
      color: var(--background-primary);
      @include respond-below('phone') {
        font-size: 3rem;
      }
    }

    &-content {
      padding: 2rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background-color: var(--background-secondary);
      gap: 0.5rem;
    }
    &-property {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &-value {
        font-weight: bold;
        text-transform: uppercase;
      }
      &-label {
        opacity: 0.75;
      }
    }
  }
}
</style>
