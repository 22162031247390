<template>
  <div class="project-properties">
    <div v-if="items && items.length" class="project-properties__content">
      <template v-for="(item, index) in items">
        <div
          :key="index"
          class="project-properties__item-icon"
          :style="{ backgroundImage: `url(${item.icon.url})` }"
          :title="item.label"
        ></div>
        <div :key="index + 'image'" class="project-properties__item-description">
          {{ item.label }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'

export default {
  computed: {
    ...mapState('Project', ['item']),

    items() {
      return GetCustomField('project-properties', this.item.customFields) || []
    },
  },
  methods: {
    checkActive(index) {
      if (this.items[index]['check-progress']) return false
      //index = 0 is always active
      if (index === 0) return true
      if (this.items[index]['check-progress']) return true
      return false
    },
  },
}
</script>

<style lang="scss">
.project-properties {
  $iconSize: 100px;
  margin: 0 auto;

  &__content {
    color: white;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    align-items: center;

    @include respond-below('tablet') {
      gap: 0.5rem;
    }
  }

  &__item {
    &-icon {
      flex-grow: 1;
      width: $iconSize;
      height: $iconSize;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      background-origin: content-box;
      @include respond-below('tablet') {
        width: calc(#{$iconSize}/ 2);
        height: calc(#{$iconSize}/ 2);
        font-size: 0.8em;
        svg {
          font-size: 0.8em;
        }
      }
    }

    &-description {
      font-size: 0.9rem;
      font-family: var(--secondary-font);
      text-transform: uppercase;
      @include respond-below('tablet') {
        font-size: 0.6rem;
      }
      @include respond-below('phone') {
        font-size: 0.4rem;
      }
    }
  }
}
</style>
