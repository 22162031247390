<template>
  <Flatfinder
    class="residential-flatfinder"
    :value="flatfinders"
    :suggested="suggestedFlatfinder"
    :properties="properties"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Flatfinder from '@kvass/flatfinder'

export default {
  computed: {
    ...mapGetters('Residential', ['flatfinders', 'suggestedFlatfinder']),
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  components: {
    Flatfinder,
  },
}
</script>
<style lang="scss">
.flatfinder__title {
  color: var(--primary-contrast);
  text-align: left;
}
// .flatfinder-filter__item {
//   background: transparent;
//   border: 1px solid var(--primary);
// }
// .flatfinder__container {
//   padding: 0;
// }
</style>
