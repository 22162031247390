<template>
  <div v-if="content" class="cta">
    <div v-if="content['cta']" class="cta__trigger ">
      <div class="cta__trigger-content" v-if="!decrease">
        <ButtonComponent
          class="cta__trigger-decrease"
          :icon="['fal', 'times']"
          @click.stop="decrease = true"
        />
        <div v-if="content" class="cta__trigger-label" v-html="content['cta']"></div>
        <ButtonComponent
          class="cta__modal-action"
          theme="secondary"
          :label="$t('leadFormTitle')"
          @click="navigate"
        />
      </div>
      <ButtonComponent
        v-else
        class="cta__trigger-increase"
        :icon="['far', 'arrow-left']"
        @click.stop="decrease = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { ModalComponent as Modal } from 'vue-elder-modal'
import { GetCustomField, ScrollToAnchor } from '@/utils'

export default {
  data() {
    return {
      decrease: false,
    }
  },
  computed: {
    ...mapState('Project', ['item']),

    content() {
      if (!this.item) return {}
      return GetCustomField('cta', this.item.customFields) || {}
    },
  },
  methods: {
    navigate() {
      ScrollToAnchor('lead')
    },
  },
  components: {
    Modal,
  },
}
</script>

<style lang="scss">
.cta {
  &__trigger {
    $padding-y: 1rem;
    $padding-x: 1rem;
    position: absolute;
    right: 0;
    top: 20%;
    background-color: var(--background-secondary);
    color: black;

    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 0 0 $border-radius $border-radius;
    z-index: get-layer('cta');
    white-space: nowrap;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    @include respond-below('phone') {
      top: 20%;
    }

    &-content {
      padding: $padding-y $padding-x;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      max-width: 350px;

      @include respond-below('phone') {
        padding: 1rem;
        gap: 0.5rem;
        max-width: 200px;
        .elder-button {
          font-size: 0.9em;
        }
      }
    }

    &-label {
      text-align: center;
      white-space: break-spaces;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      h2 {
        color: var(--primary);
        margin-bottom: 0.5rem;
        @include respond-below('phone') {
          margin: 0;
        }
      }
      p {
        font-size: 0.8em;
      }

      p {
        margin: 0;
      }
    }
    &-decrease {
      position: absolute !important;
      right: 0;
      top: 0;
      border: none !important;
      .elder-button {
        &__icon {
          padding: 0.5rem !important;
          svg {
            font-size: 1rem;
          }
        }
      }
    }
    &-increase {
      background-color: var(--secondary) !important;
      border: none !important;
      min-height: initial;

      .elder-button {
        &__icon {
          display: flex;

          &:first-child:last-child {
            padding: 1rem;
          }
          svg {
            font-size: 1.3rem;
            @include respond-below('phone') {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
</style>
